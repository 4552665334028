:root {
  --main: #132c5b;
  --secondary: #0cc2f7;
}
body {
  margin: 0;
  padding: 0;
}
.app {
  max-width: 1170px;
  /* min-width: 100px; */
  margin: 0 auto;
  font-size: 18px;
}

nav {
  margin: 0;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0px 8px 7px -2px rgba(129, 129, 129, 0.53);
  -webkit-box-shadow: 0px 8px 7px -2px rgba(129, 129, 129, 0.53);
  -moz-box-shadow: 0px 8px 7px -2px rgba(129, 129, 129, 0.53);
}
/* 
@media only screen and (max-width: 600px) {
  .app {
    width: 100%;
  }
} */
